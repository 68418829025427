@font-face {
	font-family: 'AudiTypeScreen';
	src: url('../../fonts/AudiTypeScreen-Normal.woff') format('woff'),
		url('../../fonts/AudiTypeScreen-Normal.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'AudiTypeScreen';
	src: url('../../fonts/AudiTypeScreen-Bold.woff') format('woff'),
		url('../../fonts/AudiTypeScreen-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'AudiTypeExtended';
	src: url('../../fonts/AudiTypeExtended-Bold.woff') format('woff'),
		url('../../fonts/AudiTypeExtended-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
