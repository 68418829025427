.button {
	@include button-reset;
	padding: 12px 30px;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;

	background-color: var(--color-black);
	color: var(--color-white);
	font-size: 16px;
	line-height: 28px;
	text-decoration: none;
	transition: opacity $transition-props;

	@include hover {
		opacity: 0.7;
	}

	&[disabled] {
		opacity: 0.4;
		pointer-events: none;
	}
}
