.service-details {
	padding: 48px;
	position: relative;
	background-color: var(--color-beige);
	text-align: center;

	@include mobile {
		padding: 16px;
		text-align: left;
	}
}

.service-details__close {
	@include button-reset;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 25px;
	right: 25px;
	transition: opacity $transition-props;

	@include hover {
		opacity: 0.3;
	}

	@include mobile {
		top: 12px;
		right: 12px;
	}
}

.service-details__title {
	@include font('AudiTypeExtended');
	margin: 0 auto;
	margin-bottom: 24px;
	font-size: 32px;
	line-height: 44px;
	// text-align: center;

	@include mobile {
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 24px;
	}
}

.service-details__text {
	margin: 0;
	font-size: 16px;
	line-height: 28px;
	// text-align: center;

	&:not(:last-of-type) {
		margin-bottom: 24px;
	}
}

.service-details__button {
	display: flex;
	margin: 40px auto 0;
	max-width: 260px;
	width: 100%;

	@include mobile {
		margin-top: 24px;
	}
}
