.apps {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	@include mobile {
		flex-direction: column;
		align-items: center;
	}
}

.apps__title {
	width: 100%;
	margin-bottom: 25px;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
}

.apps__link {
	display: flex;
	width: 150px;
	height: 50px;
	&:not(:last-of-type) {
		margin-right: 24px;
	}

	@include mobile {
		&:not(:last-of-type) {
			margin-right: 0;
			margin-bottom: 16px;
		}
	}
}
