.check-result {
	width: 100%;
	padding: 64px 18px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: var(--color-beige);

	@include mobile-xl {
		padding: 48px 18px;
	}
}

.check-result__icon {
	width: 64px;
	height: 64px;
	margin-bottom: 16px;
}

.check-result__buttons {
	display: flex;
	justify-content: center;
	width: 100%;
}

.check-result__btn {
	width: 100%;
	max-width: 260px;
}

.check-result__apps {
	width: 100%;
	padding-bottom: 48px;
	margin-bottom: 40px;
	border-bottom: 1px solid var(--color-gray-1);

	@include mobile {
		margin-bottom: 40px;
	}
}

.check-result__services {
	width: 100%;
	margin-bottom: 40px;
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	justify-content: center;

	@include mobile {
		margin-bottom: 32px;
	}

	&--single {
		grid-template-columns: minmax(280px, 662px);

		.check-result__services-item {
			min-height: unset;
			align-items: flex-start;
		}
	}

	&--double {
		grid-template-columns: repeat(2, 315px);

		@include mobile-xl {
			grid-template-columns: 1fr;
		}
	}
}

.check-result__services-item {
	margin: 0;
	padding: 24px;
	min-height: 180px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: var(--color-gray-bg);
}

.check-result__service-code {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 4px;
}

.check-result__service-name {
	margin-bottom: 12px;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700px;
}

.check-result__service-link {
	margin-top: auto;

	font-size: 14px;
	line-height: 24px;
	text-decoration: none;
	color: var(--color-red-text);
	transition: opacity $transition-props;
	cursor: pointer;

	@include hover {
		opacity: 0.6;
	}
}

.check-result__title {
	@include font('AudiTypeExtended');
	margin: 0;
	margin-bottom: 25px;

	font-size: 24px;
	line-height: 32px;
	text-align: center;

	@include mobile-xl {
		margin-bottom: 20px;
	}
}

.check-result__text {
	margin: 0;
	margin-bottom: 40px;
	line-height: 28px;
	text-align: center;
}
