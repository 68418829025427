.service-check {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.service-check__title {
	@include font('AudiTypeExtended');
	margin: 0;
	margin-bottom: 26px;
	font-weight: 700;
	font-size: 56px;
	line-height: 64px;
	text-align: center;

	@include tablet {
		font-size: 40px;
		line-height: 48px;
	}

	@include mobile {
		font-size: 32px;
		line-height: 40px;
	}
}

.service-check__text {
	margin: 0;
	margin-bottom: 26px;
	max-width: 868px;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
}

.service-check__disclaimer {
	margin: 0;
	margin-top: 64px;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: var(--color-disclaimer);

	@include mobile-xl {
		margin-top: 48px;
	}
}
